<template>
  <b-card header-tag="header" title="Ürün Satış İstatistiği">

    <div class="mb-3">
      <b-btn-group>
        <b-button size="sm" variant="primary" @click="length = 10">
          10 Kayıt Göster
        </b-button>
        <b-button size="sm" variant="primary" @click="length=0">
          Tümü Göster
        </b-button>
        <b-button size="sm" variant="secondary" @click="getProductStats('all')">
          Tüm Zaman
        </b-button>
        <b-button size="sm" variant="secondary" @click="getProductStats('today')">
          Bugün
        </b-button>
        <b-button size="sm" variant="secondary" @click="getProductStats('this_month')">
          Bu Ay
        </b-button>
        <b-button size="sm" variant="secondary" @click="getProductStats('last_3_months')">
          Son 3 Ay
        </b-button>
        <b-button size="sm" variant="secondary" @click="getProductStats('last_6_months')">
          Son 6 Ay
        </b-button>
        <b-button size="sm" variant="secondary" @click="getProductStats('this_year')">
          Bu Yıl
        </b-button>
        <b-button size="sm" variant="secondary" @click="getProductStats('last_1_year')">
          Son 1 Yıl
        </b-button>
      </b-btn-group>
    </div>

    <b-table
        striped
        hover
        :items="getItems"
        :fields="fields"
    >
      <template #cell(barcode)="data">
        <b-badge v-b-tooltip.hover :title="data.item.name">{{ data.value }}</b-badge>
      </template>
    </b-table>
  </b-card>
</template>
<script>
export default {
  name: 'ProductStats',
  data: () => ({
    length: 10,
    period: '',
    items: [],
    fields: [
      {
        key: 'brand_name',
        label: 'Marka',
        sortable: true,
      },
      {
        key: 'barcode',
        label: 'Barkod',
        sortable: true,
      },
      {
        key: 'count',
        label: 'Sipariş',
        sortable: true,
        class: "text-right", // Sağ hizalama
        tdClass: "text-right" // Hücreyi sağa hizala
      },
      {
        key: 'sum',
        label: 'Adet',
        sortable: true,
        class: "text-right", // Sağ hizalama
        tdClass: "text-right" // Hücreyi sağa hizala
      },
      {
        key: "sum_price",
        label: "Toplam Fiyat",
        formatter: (value) =>
            value
                ? new Intl.NumberFormat("tr-TR", {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(value)
                : "0,00",
        sortable: true,
        class: "text-right", // Sağ hizalama
        tdClass: "text-right" // Hücreyi sağa hizala
      },
      {
        key: 'price',
        label: 'Ortalama Fiyat',
        formatter: (value) =>
            value
                ? new Intl.NumberFormat("tr-TR", {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(value)
                : "0,00",
        sortable: true,
        class: "text-right", // Sağ hizalama
        tdClass: "text-right" // Hücreyi sağa hizala
      },
    ]
  }),
  computed: {
    getItems() {
      if (this.length === 0) return this.items;
      return this.items.slice(0, this.length)
    }
  },
  methods: {
    getProductStats(period) {
      this.items = [];
      this.$store.dispatch('statistics/productStats', {period})
          .then(res => {
            this.items = res;
          })
    }
  },
  mounted() {
    this.getProductStats('all')
  }
}
</script>

<style>
td.text-right {
  font-variant-numeric: tabular-nums;
}
</style>